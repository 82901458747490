import * as React from "react"
import "./stayedQuestions.scss"

import Title from "../Title"
import AccordionBlock from "./AccordionBlock"

export default function StayedQuestions(props) {
  const { data } = props

  return (
    <div className="stayedQuestions">
      <div className="container">
        <div className="stayedQuestions__block">
          <div className="stayedQuestions__side">
            <Title value={[["dark", "Остались вопросы?"]]} />
          </div>

          <div className="stayedQuestions__side">
            <AccordionBlock data={data} classAdd={"accordion__block--empty"} />
          </div>
        </div>
      </div>
    </div>
  )
}
