import * as React from "react"

export default function GlassCard(props) {
  const { title } = props
  return title ? (
    <div className="glassCard">
      <span>{title}</span>
    </div>
  ) : (
    <></>
  )
}
