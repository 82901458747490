import * as React from "react"
import "./accordion.scss"

export default function AccordionBlock(props) {
  const { data, classAdd } = props
  const boxMap = data.map((item, i) => {
    const attr = item?.text
      ? {
          onClick: e => openBox(e),
        }
      : {}
    return (
      <div className="accordion__box" key={i}>
        <div className="accordion__box-head">
          {item?.text && (
            <button
              type="button"
              className="button-plus"
              aria-label="button show more"
            />
          )}
          <span {...attr}>{item.name}</span>
        </div>

        <div className="accordion__box-body">
          <div className="accordion__box-wrapper">
            <p className="accordion__text">{item.text}</p>
          </div>
        </div>
      </div>
    )
  })

  function openBox(event) {
    const th = event.target
    const button = th.previousSibling
    const body = th
      .closest(".accordion__box")
      .querySelector(".accordion__box-body")
    const wrapper = th
      .closest(".accordion__box")
      .querySelector(".accordion__box-wrapper")

    if (button.classList.contains("active")) {
      button.classList.remove("active")
      body.style.height = "0"
    } else {
      button.classList.add("active")
      body.style.height = wrapper.offsetHeight + "px"
    }
  }

  return (
    <div className={`accordion__block ${classAdd ? classAdd : ""}`}>
      {boxMap}
    </div>
  )
}
