import * as React from "react"
import { useRef } from "react"
import GlassCard from "./GlassCard"
import { motion } from "framer-motion"

//data это список строк, которые мы хотим отобразить string[]
export default function GlassCards(props) {
  const { data } = props
  const ref = useRef(null)

  return (
    <div className="container" ref={ref}>
      <motion.div className="glassCards" dragConstraints={ref} drag={"x"}>
        {data.map((item, index) => (
          <GlassCard key={index} title={item} i={index} />
        ))}
      </motion.div>
    </div>
  )
}
