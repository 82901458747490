import * as React from "react"
import { AnimatePresence } from "framer-motion"
import { graphql } from "gatsby"
import { useState } from "react"
import "./course.scss"

import TeacherPopup from "../components/popups/TeacherPopup"
import VideoPopup from "../components/popups/VideoPopup"
import FormPopup from "../components/popups/FormPopup"
import HeadComp from "../components/HeadComp"
import { STRAPI_API } from "../consts/api"
import GlassCards from "../components/cards/GlassCards"
import Title from "../components/Title"
import TextGrayCards from "../components/cards/TextGrayCards"
import TrialLesson from "../components/offers/TrialLesson"
import AccordionBlock from "../components/accordions/AccordionBlock"
import HelpChoosing from "../components/offers/HelpChoosing"
import TeachersMain from "../components/TeachersMain"
import EnrollCourse from "../components/offers/EnrollCourse"
import StayedQuestions from "../components/accordions/StayedQuestions"
import Layout from "../components/Layout"
import {SEO} from "../components/seo";

const Course = ({ data, location }) => {
  const dataCourse = data?.allStrapiCourse?.nodes[0]

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const [formPopup, setFormPopup] = useState(false)

  const [teacherPopup, setTeacherPopup] = useState(false)
  const [currentTeacher, setCurrentTeacher] = useState({})

  const mapHeadData = data => {
    return {
      mainVideo: {
        url: data?.video?.url,
      },
      ...data,
    }
  }

  const mapGlassCards = data => {
    return [data.s1, data.s2, data.s3, data.s4]
  }

  const mapAccordionData = data => {
    return data.map(item => {
      return {
        name: item.title,
        text: item.item,
      }
    })
  }

  const mapAfterCourse = data => setTextObj(data)

  const mapHowTextCard = data => setTextObj(data)

  const mapTextCardPromotion = data => {
    return [{ title: data.item1 }, { title: data.item2 }, { title: data.item3 }]
  }

  const mapFormOffer = data => {
    return {
      title: data.title,
      subTitle: data.subtitle,
      button: data.button,
      price: data.price,
      inputHeader: data.inputHeader,
      subPrice: data.subPrice,
    }
  }

  function setTextObj(data) {
    return Object.values(data)
      .filter(i => i !== null)
      .map(item => {
        return {
          title: item.title,
          text: item.subtitle,
        }
      })
  }

  return (
    <div>
      <SEO title={dataCourse?.titleDesc?.pageTitle} description={dataCourse?.titleDesc?.pageDescription}/>
      <Layout classAdd="coursePage" location={location}>
        <HeadComp
          headData={mapHeadData(dataCourse)}
          setVideoSrc={setVideoSrc}
          setPopUpVideo={setPopUpVideo}
          setFormPopup={setFormPopup}
          posterImgSrc={STRAPI_API + dataCourse?.CourseCard?.boxImg?.url}
        />

        {
          // TEXT GLASS CARD
          dataCourse?.benefit && (
            <div className="sliderBoxs">
              <GlassCards data={mapGlassCards(dataCourse.benefit)} />
            </div>
          )
        }

        {
          // TEXT START CARDS
          dataCourse?.purposeCourse && (
            <div className="m140">
              <div className="container">
                <Title
                  value={[
                    ["dark", "Тебе "],
                    ["red", " подойдет "],
                    ["dark", "этот курс, если ты"],
                  ]}
                />
              </div>

              <div className="container m60_40">
                {/*FIXME убрать эту дичь*/}
                <TextGrayCards
                  data={mapHowTextCard(dataCourse?.purposeCourse)}
                  classAdd={
                    dataCourse?.purposeCourse.item3
                      ? "textCard--three textCard--bigText"
                      : "textCard--two textCard--bigText"
                  }
                />
              </div>
            </div>
          )
        }

        {
          // TEXT 6 CARDS
          dataCourse?.afterCourse && (
            <div className="m140">
              <div className="container">
                <Title
                  value={[
                    ["dark", "После курса ты "],
                    ["red", "будешь "],
                  ]}
                />
              </div>

              <div className="container m60_40">
                <TextGrayCards
                  data={mapAfterCourse(dataCourse?.afterCourse)}
                  classAdd={"textCard--three"}
                  view={"check"}
                />
              </div>
            </div>
          )
        }

        {
          // OFFER TRIAL LESSON BUTTON
          dataCourse?.targetOffer && (
            <div className="container m140">
              <TrialLesson
                data={dataCourse?.targetOffer}
                setFormPopup={setFormPopup}
              />
            </div>
          )
        }

        {
          // TEXT 4 CARDS
          dataCourse?.howLearning && (
            <div className="m140">
              <div className="container">
                <Title
                  value={[
                    ["red", "Как "],
                    ["dark", "проходит обучение на этом курсе"],
                  ]}
                />
              </div>

              <div className="container m60_40">
                <TextGrayCards
                  data={mapHowTextCard(dataCourse?.howLearning)}
                  view={"numbering"}
                />
              </div>
            </div>
          )
        }

        {
          // ACCORDION
          dataCourse?.CurriculumItems && (
            <div className="container m140 courseAccordion">
              {dataCourse?.timeCourse && (
                <Title
                  value={[
                    ["red", "Программа "],
                    ["dark", "обучения — "],
                    ["dark", `${dataCourse.timeCourse}`],
                  ]}
                  classAdd="m140"
                  subTitle={`${dataCourse.curriculumSubtitle}`}
                />
              )}

              <AccordionBlock
                data={mapAccordionData(dataCourse.CurriculumItems)}
                classAdd={"accordion--titleNarrow m60_40"}
              />
            </div>
          )
        }

        {
          // OFFER 2
          dataCourse?.offer2 && (
            <div className="container m140">
              <HelpChoosing data={dataCourse.offer2} />
            </div>
          )
        }

        {
          // TEACHERS
            dataCourse?.teachers && (
            <div className="teachersMain m140">
              <div className="container">
                <Title
                  value={[
                    ["red", "Преподаватели  "],
                    ["dark", " курса"],
                  ]}
                  classAdd="teachersMain--title"
                />
              </div>

              <TeachersMain
                data={dataCourse?.teachers}
                setCurrentTeacher={setCurrentTeacher}
                setTeacherPopup={setTeacherPopup}
                classAdd={"m60_40"}
              />
            </div>
          )
        }

        {
          // TEXT CARD ONLY TITLE
          dataCourse?.promotion && (
            <div className="textThreeCards m140">
              <div className="container">
                <Title
                  value={[
                    ["red", "Выпускников "],
                    ["dark", "ждёт помощь в продвижении"],
                  ]}
                  subTitle={
                    "По окончании курса тебя ждет бесплатное участие в системе по продвижению Pioneer DJ Family"
                  }
                />
              </div>

              <div className="container m60_40">
                <TextGrayCards
                  data={mapTextCardPromotion(dataCourse.promotion)}
                  classAdd={"textCard--three textCard--bigText"}
                />
              </div>
            </div>
          )
        }

        {
          // FORM OFFER
          dataCourse?.offer3 && (
            <div className="container m140">
              <EnrollCourse data={mapFormOffer(dataCourse?.offer3)} />
            </div>
          )
        }

        {
          // QUESTIONS
          dataCourse?.questions && (
            <StayedQuestions data={mapAccordionData(dataCourse?.questions)} />
          )
        }
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {teacherPopup && (
          <TeacherPopup
            open={teacherPopup}
            setOpen={setTeacherPopup}
            currentTeacher={currentTeacher}
          />
        )}

        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    allStrapiCourse(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        subtitle
        mainButton
        video {
          url
        }
        timeCourse
        curriculumSubtitle
        price
        CourseCard {
          title
          subtitle
          boxImg {
            url
          }
          item1 {
            title
            subtitle
          }
          item2 {
            title
            subtitle
          }
          item3 {
            title
            subtitle
          }
          item4 {
            title
            subtitle
          }
        }
        benefit {
          s1
          s2
          s3
          s4
        }
        purposeCourse {
          item1 {
            title
            subtitle
          }
          item2 {
            title
            subtitle
          }
          item3 {
            title
            subtitle
          }
        }
        afterCourse {
          s1 {
            title
            subtitle
          }
          s2 {
            title
            subtitle
          }
          s3 {
            title
            subtitle
          }
          s4 {
            title
            subtitle
          }
          s5 {
            title
            subtitle
          }
          s6 {
            title
            subtitle
          }
        }
        targetOffer {
          title
          subtitle
          button
        }
        howLearning {
          s1 {
            title
            subtitle
          }
          s2 {
            title
            subtitle
          }
          s3 {
            title
            subtitle
          }
          s4 {
            title
            subtitle
          }
        }
        CurriculumItems {
          title
          item
        }
        offer2 {
          title
          subtitle
          button
        }
        offer3 {
          subPrice
          title
          subtitle
          button
          priceForStudent
          inputHeader
          price
        }
        promotion {
          item1
          item2
          item3
        }
        questions {
          title
          item
        }
        titleDesc {
          pageTitle
          pageDescription
        }
        teachers {
          strapi_id
          djName
          teacher_number
          name
          slug
          teacherCard {
            subtitle
            cover {
              url
            }
          }
          photo {
            url
          }
          mainDescription
          socialLinks {
            socials
            link
          }
        }
      }
    }
    
  }
`


export default Course
